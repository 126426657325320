@import '../../styles/theme';

.full-page-spinner {
  @include flex-center;

  height: 100vh;
  width: 100%;
  align-items: center;
}

.negative-top {
  margin-top: -59px; // height of bootstrap navbar
}